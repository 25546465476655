.Maintenance {
  height: 100vh;
  background-color: var(--darkgrey);
  text-align: center;
}

.Alert {
  text-align: center;
}

.Alert p {
  color: var(--darkgreen);
  font-size: 21px;
  padding-top: 30vh;
}


.Alert button{
    margin-top: 3vh;
    padding: 20px;
    background-color: var(--lightorange);
    color: white;
    font-size: 18px;

}
