@font-face {
  font-family: "Roboto Condensed";
  src: url("./static/fonts/RobotoCondensed-Bold.ttf");
}
@font-face {
  font-family: "SF Pro Text Regular";
  src: url("./static/fonts/SF-Pro-Text-Regular.otf");
}
@font-face {
  font-family: "SF Pro Text light";
  src: url("./static/fonts/SF-Pro-Text-Light.otf");
}
@font-face {
  font-family: "SF Pro Text Medium";
  src: url("./static/fonts/SFProText-Medium.ttf");
}
@font-face {
  font-family: "SF Pro Text Semibold";
  src: url("./static/fonts/SF-Pro-Text-Semibold.otf");
}

@font-face {
  font-family: "wml";
  src: url("./static/fonts/LogimoIconset.otf");
}

:root {
  --red: #fc5254;
  --darkred: #cc2a2a;
  --lightred: #fd8992;
  --orange: #ff8f15;
  --lightorange: #fdb750;
  --lightlightorange: #ffc46e;
  --darkbrown: #a08666;
  --brown: #bd9e77;
  --lightbrown: #f0d1a8;
  --primary-brown: #bc9e79;
  --beige: #faecdb;
  --lightbeige: #fcf6f2;
  --darkdarkgrey: #2c302e;
  --darkgrey: #4d4d4d;
  --newgrey: #8e8e93;
  --grey: #707070;
  --lightgrey: #e4e4e4;
  --lightlightgrey: #f7f7f7;
  --white: #ffffff;
  --darkgreen: #00ce87;
  --green: #29e394;
  /* Font/text values */
  --unnamed-font-family-roboto: Roboto;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-20: 20px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-24: 24px;
  --clickable-font-size: 18px;
  --clickable-hover-font-size: 17px;

  --clickable-spacing: 14.5px 21px;

  --radius: 5px;
}

.LogimoSplash {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

html,
body {
  box-sizing: border-box;
  background-color: linear-gradient(#4d4d4d, #000000) !important;
  font-family: "SF Pro Text Regular";
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

a:focus,
a:active {
  box-shadow: none !important;
  border-color: none !important;
}

select,
input,
textarea,
button {
  border: none;
}

select:focus,
input:focus,
textarea:focus,
button:focus {
  box-shadow: none !important;
  border-color: var(--orange);
}

.dark input:focus {
  border-color: black !important;
}
.form-control {
  border-radius: 0.5rem;
}
.dark .form-control {
  border: 1px solid black;
}
.form-control option a:hover {
  background-color: red !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #feb686;
  outline: 0;
  box-shadow: none !important;
}
.rbt-menu a:hover,
.rbt-menu a:focus,
.rbt-menu a:active {
  background-color: var(--lightorange) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .swal-button{
  background-color: var(--lightorange) !important;
}
.swal-text {
  color: black !important;
} */
/* swal general styles */
.swal-modal {
  background-color: #000000;
}
.swal-title,
.swal-text {
  color: white;
}

.swal-footer {
  text-align: center;
}
/* swal failed styles */
.swal-custom-failed .swal-title {
  color: #f29797;
}

.swal-custom-failed .swal-button {
  background-color: #e53030;
  color: white;
  width: 230px;
  height: 63px;
}
.swal-custom-failed .swal-button:hover {
  background-color: #e53030;
}

/* swal success styles */
.swal-custom-success .swal-button {
  background-color: #6dc965;
  color: white;
  width: 230px;
  height: 63px;
}
.swal-custom-success .swal-title {
  color: #b6e4b2;
}
.swal-custom-success .swal-button:hover {
  background-color: #6dc965;
}
/* swal warning styles */
.swal-custom-warning .swal-button--danger {
  background-color: #6dc965;
  width: 143px;
  height: 61px;
}
.swal-custom-warning .swal-button--danger:hover {
  background-color: #6dc965;
}
.swal-custom-warning .swal-button--cancel {
  background-color: #e53030;
  color: white;
  width: 143px;
  height: 61px;
}
.swal-custom-warning .swal-button--cancel:hover {
  background-color: #e53030;
  color: white;
}
.dark .form-check-input {
  background-color: black !important;
}
.form-check-input:checked {
  border-color: unset !important;
  background-image: url("../src/static/images/circle.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 70% !important;
}
.form-check-input[type="checkbox"] {
  border-radius: 50%;
}

.form-switch .form-check-input {
  width: 3em !important;
  height: 1.4rem !important;
  float: right !important;
  margin-left: unset !important;
}
.form-select:focus {
  border-color: var(--orange);
}
.dark .checkTitle::before,
.dark .closeTitle::before,
.dark .locate::before,
.dark .sort::before,
.dark .setting::before,
.dark .home::before,
.dark .back::before,
.dark .close::before {
  color: black !important;
}
.dark .innerSearch::before {
  color: #e5e5e5;
  font-size: 26px;
}

.plus::before {
  font-family: "wml";
  font-size: 50px;
  color: white; /* Custom colour. */
  content: "a"; /* The custom Unicode (aka. PUA) for the icon you want. */
}

/* .plus::before {
    font-family: "wml";
    font-size: 25px;
    font-weight: 300;
    content: 'a'; /* The custom Unicode (aka. PUA) for the icon you want. */
/* color: rgba(0, 0, 0, 0.788); Custom colour. */
/* content: 'V'; The custom Unicode (aka. PUA) for the icon you want. */
/* } */
.minus::before {
  font-family: "wml";
  font-size: 50px;
  color: white; /* Custom colour. */
  content: "b"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.Innersetting::before {
  font-family: "wml";
  font-size: 37px;
  color: black; /* Custom colour. */
  content: "j"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.dark .setting::before {
  font-family: "wml";
  font-size: 40px;
  color: white !important; /* Custom colour. */
  content: "j"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.dark .edit::before {
  font-family: "wml";
  font-size: 40px;
  color: white !important; /* Custom colour. */
  content: "Q"; /* The custom Unicode (aka. PUA) for the icon you want. */
}

.dark .shopping::before {
  font-family: "wml";
  font-size: 40px;
  color: white !important; /* Custom colour. */
  content: "i"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.dark .Parcel::before {
  font-family: "wml";
  font-size: 37px;
  color: black !important; /* Custom colour. */
  content: "F"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.dark .Cube::before {
  font-family: "wml";
  font-size: 37px;
  color: black !important; /* Custom colour. */
  content: "M"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.bell::before {
  font-family: "wml";
  font-size: 40px;
  color: black; /* Custom colour. */
  content: "k"; /* The custom Unicode (aka. PUA) for the icon you want. */
}

.incomming::before {
  font-family: "wml";
  font-size: 37px;
  color: white; /* Custom colour. */
  content: "A"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.dark .incomming::before,
.dark .order::before,
.dark .stock::before,
.dark .account::before,
.dark .home::before {
  color: #9f9999 !important;
}

.order::before {
  font-family: "wml";
  font-size: 37px;
  color: white; /* Custom colour. */
  content: "C"; /* The custom Unicode (aka. PUA) for the icon you want. */
}

.stock::before {
  font-family: "wml";
  font-size: 37px;
  color: white; /* Custom colour. */
  content: "B"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.account::before {
  font-family: "wml";
  font-size: 37px;
  color: white; /* Custom colour. */
  content: "D"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.profile::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "D"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.Modules::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "d"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.privacy::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "f"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.print::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "7"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.accountNotification::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "k"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.payment::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "e"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.support::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "8"; /* The custom Unicode (aka. PUA) for the icon you want. */
}

.home::before {
  font-family: "wml";
  font-size: 37px;
  color: white; /* Custom colour. */
  content: "l"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.home-outline::before {
  font-family: "wml";
  font-size: 37px;
  content: "L"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.back::before {
  font-family: "wml";
  font-size: 40px;
  color: black; /* Custom colour. */
  content: "Z"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.scan::before {
  font-family: "wml";
  font-size: 27px;
  color: white; /* Custom colour. */
  content: "1"; /* The custom Unicode (aka. PUA) for the icon you want. */
  margin-bottom: -10px;
}
.search::before {
  font-family: "wml";
  font-size: 30px;
  color: white; /* Custom colour. */
  content: "4"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.innerSearch::before {
  font-family: "wml";
  font-size: 30px;
  color: black; /* Custom colour. */
  content: "4"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.close::before {
  font-family: "wml";
  font-size: 30px;
  color: black; /* Custom colour. */
  content: "2"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.Innerclose::before {
  font-family: "wml";
  font-size: 25px;
  color: #e5e5e5; /* Custom colour. */
  content: "2";
}
.closeTitle::before {
  font-family: "wml";
  font-size: 35px;
  color: black; /* Custom colour. */
  content: "2"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.cancel::before {
  font-family: "wml";
  font-size: 27px;
  color: white; /* Custom colour. */
  content: "2"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.checkbox::before {
  font-family: "wml";
  font-size: 36px;
  color: black; /* Custom colour. */
  content: "B"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.checkbox-inner::before {
  font-family: "wml";
  font-size: 36px;
  color: #1c9a3c; /* Custom colour. */
  content: "B"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.sort::before {
  font-family: "wml";
  font-size: 40px;
  color: black; /* Custom colour. */
  content: "V"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.sort-light::before {
  font-family: "wml";
  font-size: 32px;
  color: rgb(255, 255, 255); /* Custom colour. */
  content: "V"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.Label::before {
  font-family: "wml";
  font-size: 40px;
  color: white; /* Custom colour. */
  content: "6"; /* The custom Unicode (aka. PUA) for the icon you want. */
}

.locate::before {
  font-family: "wml";
  font-size: 30px;
  color: black; /* Custom colour. */
  content: "5"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.check::before {
  font-family: "wml";
  font-size: 27px;
  color: white; /* Custom colour. */
  content: "3"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.fa-info-circle:before {
  content: "\f05a";
  font-size: 30px;
}
.info-circle::before {
  font-family: "wml";
  font-size: 40px;
  content: "c"; /* The custom Unicode (aka. PUA) for the icon you want. */
  color: black; /* Custom colour. */
}
.checkTitle::before {
  font-family: "wml";
  font-size: 35px;
  color: black; /* Custom colour. */
  content: "3"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.arrowRight::before {
  font-family: "wml";
  font-size: 25px;
  color: #9f9999; /* Custom colour. */
  content: "W"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
.checkTitle::before {
  font-family: "wml";
  font-size: 35px;
  color: black; /* Custom colour. */
  content: "3"; /* The custom Unicode (aka. PUA) for the icon you want. */
}
input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 10px;
  background: var(--grey);
  border: none;
  border-radius: 3px;
}
.dark input[type="range"]::-webkit-slider-runnable-track {
  background: white;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background: var(--lightorange);
  margin-top: -7px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: white;
}

.react-toggle-track {
  width: 50px !important;
  height: 30px !important;
  padding: 0;
  background-color: #4d4d4d !important;
}
.react-toggle-thumb {
  width: 28px !important;
  height: 28px !important;
}
.react-toggle-track-x {
  display: none !important;
}
.react-toggle--checked .react-toggle-track-check {
  display: none !important;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: var(--orange) !important;
  border: 1px solid var(--orange) !important;
}

.search-input > input {
  background-color: black !important;
}

iframe {
  border: 0;
  width: 100% !important;
  height: 80vh !important;
}

canvas.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__message.react-pdf__message--error {
  color: white !important;
  text-align: center !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgb(0, 0, 0) !important;
}

.hidden-row-corridor,
.hidden-EAN {
  display: none !important;
}
