.dashboard-container {
  max-width: 1920px;
  display: flex;
}

.content-container {
  
  flex-grow: 1;
  overflow-x: hidden;
  background-color: var(--lightlightgrey);

  
  width: 100%;
}

.withPadding , .withPaddingJustLarge {
  padding: 25px;
}

.dark .content-container {
  background: linear-gradient(#4d4d4d, #000000);
  background-attachment: fixed;
}
.dark .breadcrumb {
  color: white;
}
.breadcrumb-active-custom {
  color: var(--grey);
}
.dark .breadcrumb-active-custom {
  color: var(--grey);
}
.breadcrumb-item a:hover {
  color: var(--grey) !important;
}

.bottom-mobile-menu {
  visibility: hidden;
  display: none;
}
.bottom-mobile-menu p{
margin-top: -10px;
font-size: 13px;
font-family: 'SF Pro Text Medium';
}

.menu-active-custom {
  color: black !important;
}
.dark  .menu-active-custom .incomming::before , .dark  .menu-active-custom .order::before , .dark  .menu-active-custom .stock::before{
color:white !important;
}
.dark .menu-active-custom {
  color: white !important;
}
.arrow {
  visibility: hidden;
}
.threeDot {
  cursor: pointer;
}

.dark .threeDot {
  color: white;
}
.MobileSetting::after {
  display: none !important;
}
.mainMobileSetting {
  display: none;
}



@media only screen and (max-width: 992px) {
  .mainMobileSetting {
    display: block !important;
  }
  .main-menu-mobile {
    z-index: 1000 !important;
  }
  .bottom-mobile-menu {
    visibility: visible !important;
    position: fixed;
    margin-top: 20px;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-color: var(--orange);
    color: white;
    z-index: 9999;
    padding-top: 5px;
  }
  .dark .bottom-mobile-menu {
    background-color: black;
  color: #9F9999;
}
  .bottom-mobile-menu > div {
    text-align: center;
    width: 22%;
  }
  /* .bottom-mobile-menu img {
    width: 40%;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  } */

  .main-menu-mobile a:hover,
  .main-menu-mobile a:focus {
    color: black !important;
  }
  .dark .main-menu-mobile a:hover,
  .dark .main-menu-mobile a:focus {
    color: white !important;
  }
  .circule {
    content: "";
    z-index: 10000;
    font-size: 30px;
    text-align: center;
    bottom: 52px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 80px;
    border-radius: 150px 150px 0 0;
    background-color: var(--orange);
    cursor: pointer;
  }
  .arrow {
    z-index: 10000;
    visibility: visible;
    display: flex;
    align-items: center;
    top: 100vh;
    justify-content: center;
    border: solid white;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 7px;

    cursor: pointer;
  }
  .arrow:hover {
    border: solid black;
    border-width: 0 4px 4px 0;
  }
  .changePos {
    bottom: 0 !important;
    margin-bottom: -20px;
  }
  .Up {
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .hidemenu {
    display: none !important;
  }

  .dark .content-container {
    background-color: var(--darkgrey);
  }
}

@media only screen and (max-width: 992px) {
  .withPaddingJustLarge {
    padding:unset !important
  }
}